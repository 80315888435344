import '../styles/globals.css';
import { ChakraProvider } from '@chakra-ui/react';
import {
  ApolloProvider,
} from '@apollo/client';
import { NextComponentType } from 'next';
import { ReactElement } from 'react';
import MainLayout from '../components/layouts/main';
import DefaultLayout from '../components/layouts/default';
import { useApollo } from '../lib/apollo-client';
import { UserProvider } from './api/userAuth';
import theme from '../styles/defaultTheme';
import { LoaderProvider } from '../lib/contexts/LoaderContext';
import Loader from '../components/loader/index';

import initSentry from '../utils/withSentry';
import {NavigationProvider} from "../lib/contexts/NavigationContext";

initSentry();

type IAppProps = {
  Component: NextComponentType,
  children: NextComponentType,
  pageProps: unknown
}

function UKCPAApp({ Component, pageProps }: IAppProps): ReactElement {
  const apolloClient = useApollo(pageProps);
  // eslint-disable-next-line react/prop-types
  // const Layout = Component || DefaultLayout;

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <NavigationProvider>
          <UserProvider>
            <MainLayout>
              <LoaderProvider>
                <DefaultLayout>
                  <Component {...pageProps} />
                </DefaultLayout>
                <Loader />
              </LoaderProvider>
            </MainLayout>
          </UserProvider>
        </NavigationProvider>
      </ApolloProvider>
    </ChakraProvider>
  );
}

export default UKCPAApp;
